import {inject, Injectable, NgZone} from '@angular/core';
import {EMessagesEventsChat} from "@prestinly/core-lib/lib/enums/messages/events.chat.messages.enum";
import {MessagesApiService} from "../api/ws.service";
import {Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class ChatService {
  socket = inject(MessagesApiService);
  router = inject(Router);
  zone = inject(NgZone);

  checkIsExist(userId: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.socket.emit(EMessagesEventsChat.IS_CHAT_EXISTS, {
        withUserId: userId,
      }).subscribe({
          next: (res) => {
            this.zone.run(() => {
              this.router.navigate(['/messages/chat/' + res.id]);
            });
            resolve(res.id);
          },
          error: (err) => {
            this.zone.run(() => {
              this.router.navigate(['/messages/new/' + userId]);
            });
            resolve('');
          },
        }
      );
    });
  }
}
